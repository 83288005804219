import React from 'react'
import styled from '@emotion/styled'

const Bookend = styled.div`
  width: 100%;
  display: block;
  background-color: #354b37;
`

const StyledCredits = styled.footer`
  text-align: center;
  color: #a3bdb7;
  margin-bottom: 1rem;
  > a:hover {
    color: #c78310;
  }
`

export default ({ children }) => (
  <>
    <div className="bookend"
      style={{
        height: '8px',
      }}
    />
    <div style={{ width: '100%', margin: '0 auto', minHeight: '100vh' }}>
      {children}
    </div>
    <div className="credits">
      Other
      nodes: <a rel="me" href="https://indieweb.social/@jonzfisher" target="_blank">Mastodon</a>
      , <a href="https://twitter.com/@jonzfisher" target="_blank">Twitter</a>
      , <a href="https://dribbble.com/jonzfisher" target="_blank">Dribbble</a>
    </div>
    <div className="credits">
      Made with{' '}
      <a href="https://gatsbyjs.org/" target="_blank">
        Gatsby
      </a>
      , <a href="https://www.contentful.com/" target="_blank">Contentful
        </a>, and <a href="https://www.netlify.com/" target="_blank">
          Netlify
      </a>
    </div>
    <div className="bookend"
      style={{
        height: '16px',
      }}
    />
  </>
)
